<template>
    <div 
        class="
            h-full 
            w-full
            md:w-auto md:rounded-xl md:border-8 md:border-gray-300 md:mx-auto 
        ">
        <!--
        <pre v-if="offer" class="h-64 overflow-scroll">
            tempId: <br>
            {{offer.tempId}}
            
            priceTotal:
            {{offer.priceTotal}}
            <br>
            
            priceTotalFormated:
            {{offer.priceTotalFormated}}
            <br>
            
            totalVatAmount:
            {{offer.totalVatAmount}}
            <br>
            
            priceTotalIncludedVat:
            {{offer.priceTotalIncludedVat}}
            <br>
            
            
            priceTotalIncludedVatFormated:
            {{offer.priceTotalIncludedVatFormated}}
            <br>
            
            
            offerEdit: <br>
            {{offer.offerEdit}}
            
            'tempId',
            'contact',
            'customer',
            'offerEdit',
            'priceTotal',
            'previewWidth',
            'offerProducts',
            'priceTotalFormated',
            
            priceTotal
            priceTotalFormated
            totalVatAmount
            priceTotalIncludedVat
            priceTotalIncludedVatFormated
        </pre>
        -->
        
        <iframe 
            v-if="offerEdit"
            class="mx-auto bg-white overflow-hidden h-full"
            :class="offer.previewWidth == 'mobile' && isMobile ? 'w-full' : offer.previewWidth"
            :src="src"
            ref="iframeDesign"
        />
        <!-- <pre>{{offerEdit}}</pre> -->
        
        
    </div>
</template>

<script>
export default {
    props: {
        offer: {
            type: Object,
            required: true,
        },
    },
    
    computed: {
        src(){
            return '/d/'+ this.offer.offerEdit.designId +'/i.html?a='+this.account.accountId+'&tempId='+this.offer.tempId;
        },
        
        localStorage(){
            if (localStorage[this.offer.tempId]) {
                console.log('localStorage() says yes');
                return JSON.parse( localStorage[this.offer.tempId] );
            }
            else {
                console.log('localStorage() says no' );
                return null;
            }
        },
        
        offerEdit(){
            return this.offer.offerEdit;
        }
    },
    
    data() {
        return {
            // timeout: null,
            loadText: 'loading...',
        }
    },
    
    watch: {
        offerEdit: {
            // Will fire as soon as the component is created
            immediate: true,
            deep: true,
            handler(offerEdit) {
                this.writeLocalStorage('offerEdit');
            }
        },
        
        // offerProducts: {
        //     // Will fire as soon as the component is created
        //     immediate: true,
        //     deep: true,
        //     handler(offerProducts) {
        //         // The whole view is rendered, so I can safely access or query the DOM. ¯\_(ツ)_/¯
        //         this.writeLocalStorage('offerProducts');
        //     }
        // },
    },
    
    methods: {
        // iframeLoaded(){
        //     // console.log('iframeLoaded');
        //     // this.loadText = 'iframe loaded!';
        // },
        
        writeLocalStorage( eventTrigger ){
            // console.log('writeLocalStorage '+eventTrigger);
        
            if (localStorage[this.offer.tempId]) {
                localStorage.removeItem( this.offer.tempId );
            }
            
            const data = {
                ...this.offer.offerEdit,
                products: this.offer.offerProducts,
                customer: {
                    ...this.offer.customer,
                },
                account: {
                    ...this.offer.account,
                },
                endpoint: process.env.VUE_APP_FILES_ENDPOINT + '/'+this.offer.accountId,
                contact: this.offer.contact,
                priceTotal: this.offer.priceTotal,
                priceTotalFormated: this.offer.priceTotalFormated,
                totalVatAmount: this.offer.totalVatAmount,
                totalVatAmountFormated: this.offer.totalVatAmountFormated,
                priceTotalIncludedVat: this.offer.priceTotalIncludedVat,
                priceTotalIncludedVatFormated: this.offer.priceTotalIncludedVatFormated,
            };
            
            // priceTotalAdjustDiscount: this.priceTotalAdjustDiscount,
            
            // console.log( data );
            
            localStorage[this.offer.tempId] = JSON.stringify(data);
            
            // if (this.$refs.iframeDesign ) {
            //     this.loadText = 'got ref! reload! '+this.loadText;
            //     this.$refs.iframeDesign.contentWindow.location.reload();
            // }
            // else {
            //     this.loadText = 'no ref!';
            // }
        },
    },
    
    mounted(){
        this.writeLocalStorage('mounted');
    },
    
    destroyed(){
        // console.log('destroyed');
        localStorage.removeItem( this.offer.tempId );
    } 
}
</script>

<style lang="scss" scoped>
    .mobile {
        width: 420px;
    }
    .tablet {
        width: 700px;
    }
    .laptop {
        width: 1024px;
    }
</style>
